import type {
  ComponentRef,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';
import { EditorReadyFn } from '@wix/yoshi-flow-editor';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI
) => {
  if (options.firstInstall) {
    // first page
    const pageRefFindATable = await editorSDK.document.pages.add('', {
      title: flowAPI.translations.t('pages-menu.find-table'),
      definition: {
        data: { managingAppDefId: 'a27d22ee-5c11-4947-983e-f9effb541af8' },
      },
      shouldAddMenuItem: true,
    });

    const url = await editorSDK.document.pages.pageUriSEO.convertPageNameToUrl(
      '',
      { title: flowAPI.translations.t('pages-menu.find-table') }
    );

    await editorSDK.document.pages.data.update('', {
      pageRef: pageRefFindATable,
      data: {
        pageUriSEO: url,
      },
    });

    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: '6c666466-73f9-46b5-b3da-4f14e649e52d',
      containerRef: pageRefFindATable as ComponentRef,
      installationType: 'closed' as WidgetInstallationType,
      presetIds: {
        layout: 'variants-kz2i7tja',
        style: 'variants-kz2i7tja',
      },
      layout: {
        height: 300,
        width: 980,
        x: 0,
        y: 100,
      },
    });

    // second page
    const pageRefTimeSlots = await editorSDK.document.pages.add('', {
      title: flowAPI.translations.t('pages-menu.time-slots'),
      definition: {
        data: { managingAppDefId: 'a27d22ee-5c11-4947-983e-f9effb541af8' },
      },
      shouldAddMenuItem: false,
      shouldNavigateToPage: false,
    });

    const urlTimeSlots =
      await editorSDK.document.pages.pageUriSEO.convertPageNameToUrl('', {
        title: flowAPI.translations.t('pages-menu.time-slots'),
      });

    await editorSDK.document.pages.data.update('', {
      pageRef: pageRefTimeSlots,
      data: {
        pageUriSEO: urlTimeSlots,
      },
    });

    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: '43305bff-1310-4ff4-a00f-028c3b1fc342',
      containerRef: pageRefTimeSlots as ComponentRef,
      installationType: 'closed' as WidgetInstallationType,
      presetIds: {
        layout: 'variants-kz3z3u2e',
        style: 'variants-kz3z3u2e',
      },
      layout: {
        height: 500,
        width: 980,
        x: 0,
        y: 100,
      },
    });
  }
};

export const getAppManifest = (
  { appManifestBuilder }: { appManifestBuilder },
  editorSDK
) => {
  return appManifestBuilder
    .configurePagesTab((pagesTabBuilder) => {
      pagesTabBuilder.addAction({
        title: 'Remove',
        icon: 'deleteRadio',
        type: 'REMOVE',
        onClick: async (event) => {
          await editorSDK.application.uninstall('token', {
            openConfirmation: true,
          });
        },
      });
    })
    .build();
};
