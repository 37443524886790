
  var editorScriptEntry = require('/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = {
        DSN: 'https://d7a327cdc815438eb104a34ea53f1165@sentry.wixpress.com/2818',
        id: 'd7a327cdc815438eb104a34ea53f1165',
        projectName: 'table-reservations-bob',
        teamName: 'table-reservations-web',
        
      };
      const experimentsConfig = {"centralized":true,"scopes":[]};
      const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};
      const defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to","find-table.parties-size":"{count} people","pages-menu.find-table":"Find a Table","pages-menu.time-slots":"Time Slots"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        shouldUseEssentials: true,
        artifactId: 'table-reservations-bob',
        appDefId: 'a27d22ee-5c11-4947-983e-f9effb541af8',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/components/TimeSlots/editor.controller.ts');

      const model_0 = require('/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/components/TimeSlots/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "a27d22ee-5c11-4947-983e-f9effb541af8-z5i7l";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"a27d22ee-5c11-4947-983e-f9effb541af8-z5i7l": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "a27d22ee-5c11-4947-983e-f9effb541af8-z5i7l", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);
  
      const userController_1 = require('/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/components/Widget1/editor.controller.ts');

      const model_1 = require('/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/components/Widget1/model.ts').default;

      const manifest_1 = userController_1 ? userController_1.default || userController_1 : {};
      if (!manifest_1.type) {
        manifest_1.type = "a27d22ee-5c11-4947-983e-f9effb541af8-c4xq3";
      }
      if (!manifest_1.getExports && model_1) {
        manifest_1.getExports = () => ({"a27d22ee-5c11-4947-983e-f9effb541af8-c4xq3": model_1.getExports()});
      }
      if (userController_1 && userController_1.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "a27d22ee-5c11-4947-983e-f9effb541af8-c4xq3", getWidgetManifest: userController_1.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_1);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
